var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"chat-top-command-handler-module-view"},[_c('page-title',{attrs:{"title":_vm.$t(_vm.module.model.titleKey),"subtitle":_vm.$t(_vm.module.model.pageMiniHelpMessageKey)},scopedSlots:_vm._u([{key:"custom-button",fn:function(){return [(_vm.hasBackButton)?_c('go-back-button',{on:{"go-back":_vm.goBack}}):_vm._e(),_c('save-module-button',{on:{"save-module":_vm.saveModule}})]},proxy:true}])}),_c('module-config-settings-wrapper',{attrs:{"has-conditions":_vm.module.model.config.conditionsExists}},[_c('module-config-settings',{attrs:{"disabled":!!_vm.getTagsByFieldKey('custom_tops')},model:{value:(_vm.module.model.config),callback:function ($$v) {_vm.$set(_vm.module.model, "config", $$v)},expression:"module.model.config"}})],1),_c('a-card',{staticClass:"mt-5"},[_c('multi-message-editor-with-media-input',{staticClass:"mt-0",attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.module.model.params,
          'key': 'toprankmessage',
          'targetToUpload':  _vm.groupUploadTarget,
          'editorMode': _vm.MediaEditorMode.TelegramShort,
          'tariffTags': _vm.getTagsByFieldKey('custom_tops'),
          'base-api-url': 'api.chatkeeper.app/cabinet',
          'hasAnchor': true,
          'hasMedia': true,
        },
      }}}),_c('multi-message-editor-with-media-input',{attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.module.model.params,
          'key': 'bottomrankmessage',
          'targetToUpload':  _vm.groupUploadTarget,
          'editorMode': _vm.MediaEditorMode.TelegramShort,
          'tariffTags': _vm.getTagsByFieldKey('custom_tops'),
          'base-api-url': 'api.chatkeeper.app/cabinet',
          'hasAnchor': true,
          'hasMedia': true,
        },
      }}}),_c('multi-message-editor-with-media-input',{attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.module.model.params,
          'key': 'toprefmessage',
          'targetToUpload':  _vm.groupUploadTarget,
          'editorMode': _vm.MediaEditorMode.TelegramShort,
          'tariffTags': _vm.getTagsByFieldKey('custom_tops'),
          'base-api-url': 'api.chatkeeper.app/cabinet',
          'hasAnchor': true,
          'hasMedia': true,
        },
      }}}),_c('multi-message-editor-with-media-input',{attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.module.model.params,
          'key': 'bottomrefmessage',
          'targetToUpload':  _vm.groupUploadTarget,
          'editorMode': _vm.MediaEditorMode.TelegramShort,
          'tariffTags': _vm.getTagsByFieldKey('custom_tops'),
          'base-api-url': 'api.chatkeeper.app/cabinet',
          'hasAnchor': true,
          'hasMedia': true,
        },
      }}}),_c('multi-message-editor-with-media-input',{attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.module.model.params,
          'key': 'toprepmessage',
          'targetToUpload':  _vm.groupUploadTarget,
          'editorMode': _vm.MediaEditorMode.TelegramShort,
          'tariffTags': _vm.getTagsByFieldKey('custom_tops'),
          'base-api-url': 'api.chatkeeper.app/cabinet',
          'hasAnchor': true,
          'hasMedia': true,
        },
      }}}),_c('multi-message-editor-with-media-input',{attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.module.model.params,
          'key': 'bottomrepmessage',
          'targetToUpload':  _vm.groupUploadTarget,
          'editorMode': _vm.MediaEditorMode.TelegramShort,
          'tariffTags': _vm.getTagsByFieldKey('custom_tops'),
          'base-api-url': 'api.chatkeeper.app/cabinet',
          'hasAnchor': true,
          'hasMedia': true,
        },
      }}}),_c('a-divider'),_c('multi-message-editor-with-media-input',{attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.module.model.params,
          'key': 'topapmessage',
          'targetToUpload':  _vm.groupUploadTarget,
          'editorMode': _vm.MediaEditorMode.TelegramShort,
          'tariffTags': _vm.getTagsByFieldKey('custom_tops'),
          'base-api-url': 'api.chatkeeper.app/cabinet',
          'hasAnchor': true,
          'hasMedia': true,
        },
      }}}),_c('multi-message-editor-with-media-input',{attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.module.model.params,
          'key': 'bottomapmessage',
          'targetToUpload':  _vm.groupUploadTarget,
          'editorMode': _vm.MediaEditorMode.TelegramShort,
          'tariffTags': _vm.getTagsByFieldKey('custom_tops'),
          'base-api-url': 'api.chatkeeper.app/cabinet',
          'hasAnchor': true,
          'hasMedia': true,
        },
      }}}),_c('a-divider'),_c('multi-message-editor-with-media-input',{attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.module.model.params,
          'key': 'topmsg',
          'targetToUpload':  _vm.groupUploadTarget,
          'editorMode': _vm.MediaEditorMode.TelegramShort,
          'tariffTags': _vm.getTagsByFieldKey('custom_tops'),
          'base-api-url': 'api.chatkeeper.app/cabinet',
          'hasAnchor': true,
          'hasMedia': true,
        },
      }}}),_c('multi-message-editor-with-media-input',{attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.module.model.params,
          'key': 'bottommsg',
          'targetToUpload':  _vm.groupUploadTarget,
          'editorMode': _vm.MediaEditorMode.TelegramShort,
          'tariffTags': _vm.getTagsByFieldKey('custom_tops'),
          'base-api-url': 'api.chatkeeper.app/cabinet',
          'hasAnchor': true,
          'hasMedia': true,
        },
      }}}),_c('a-divider'),_c('multi-message-editor-with-media-input',{attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.module.model.params,
          'key': 'topxp',
          'targetToUpload':  _vm.groupUploadTarget,
          'editorMode': _vm.MediaEditorMode.TelegramShort,
          'tariffTags': _vm.getTagsByFieldKey('custom_tops'),
          'base-api-url': 'api.chatkeeper.app/cabinet',
          'hasAnchor': true,
          'hasMedia': true,
        },
      }}}),_c('multi-message-editor-with-media-input',{attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.module.model.params,
          'key': 'bottomxp',
          'targetToUpload':  _vm.groupUploadTarget,
          'editorMode': _vm.MediaEditorMode.TelegramShort,
          'tariffTags': _vm.getTagsByFieldKey('custom_tops'),
          'base-api-url': 'api.chatkeeper.app/cabinet',
          'hasAnchor': true,
          'hasMedia': true,
        },
      }}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }